export const PUBLIC_PATH = process.env.REACT_APP_PUBLIC_PATH;
export const DEFAULT_LANGUAGE = process.env.REACT_APP_DEFAULT_LANGUAGE;
export const SUPPORTED_LANGUAGES = process.env.REACT_APP_SUPPORTED_LANGUAGES;
export const NAME = process.env.REACT_APP_NAME;
export const ZBOONI_FRONTEND_URL_BASE =
  process.env.REACT_APP_ZBOONI_FRONTEND_URL_BASE;
export const ZBOONI_BACKEND_API_ENDPOINT_BASE =
  process.env.REACT_APP_ZBOONI_BACKEND_API_ENDPOINT_BASE;
export const ZBOONI_BACKEND_AUTH_URL =
  process.env.REACT_APP_ZBOONI_BACKEND_AUTH_URL;
export const ZBOONI_BACKEND_CLIENT_ID =
  process.env.REACT_APP_ZBOONI_BACKEND_CLIENT_ID;
export const ZBOONI_BACKEND_CLIENT_SECRET =
  process.env.REACT_APP_ZBOONI_BACKEND_CLIENT_SECRET;
export const ZBOONI_PEOPLE_URL_BASE =
  process.env.REACT_APP_ZBOONI_PEOPLE_URL_BASE;
export const ZBOONI_PEOPLE_API_ENDPOINT_BASE =
  process.env.REACT_APP_ZBOONI_PEOPLE_API_ENDPOINT_BASE;
export const ZBOONI_PEOPLE_AUTH_URL =
  process.env.REACT_APP_ZBOONI_PEOPLE_AUTH_URL;
export const ZBOONI_PEOPLE_CLIENT_ID =
  process.env.REACT_APP_ZBOONI_PEOPLE_CLIENT_ID;
export const ZBOONI_PEOPLE_CLIENT_SECRET =
  process.env.REACT_APP_ZBOONI_PEOPLE_CLIENT_SECRET;
export const ZBOONI_REPORTING_API_ENDPOINT_BASE =
  process.env.REACT_APP_ZBOONI_REPORTING_API_ENDPOINT_BASE;
export const CLIENT_GRANT_TYPE_PASSWORD =
  process.env.REACT_APP_CLIENT_GRANT_TYPE_PASSWORD;
export const CLIENT_GRANT_TYPE_REFRESH_TOKEN =
  process.env.REACT_APP_CLIENT_GRANT_TYPE_REFRESH_TOKEN;
export const PASSWORD_RESET_URL = process.env.REACT_APP_PASSWORD_RESET_URL;
export const DEFAULT_USER_ROLE = process.env.REACT_APP_DEFAULT_USER_ROLE;
export const IDLE_TIMEOUT = process.env.REACT_APP_IDLE_TIMEOUT;
export const KEEPALIVE_INTERVAL = process.env.REACT_APP_KEEPALIVE_INTERVAL;
export const IS_THUMBNAILER_ENABELED =
  process.env.REACT_APP_IS_THUMBNAILER_ENABELED;
export const THUMBNAILER_URL = process.env.REACT_APP_THUMBNAILER_URL;
