import { DEFAULT_USER_ROLE } from "configs/appConfig";
import { getFromStorage } from "utility/storage";

const defaultState = {
  userRole: DEFAULT_USER_ROLE,
  values: {
    authInfo: getFromStorage("Session", "__zbnAuth") ?? {},
    userInfo: getFromStorage("Session", "__zbnSeller") ?? {},
  },
};

export const login = (state = defaultState, action) => {
  switch (action.type) {
    case "LOGIN_ZBNI_PEOPLE": {
      return { ...state, values: action.payload };
    }
    case "LOGOUT_ZBNI_PEOPLE": {
      return { ...state, values: action.payload };
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole };
    }
    default: {
      return state;
    }
  }
};
