import Vault from "vault.js";

export const getFromStorage = (storageType, key) => {
  return Vault[storageType].get(key);
};

export const setToStorage = (storageType, key, value, opts = {}) => {
  return Vault[storageType].set(key, value, opts);
};

export const removeFromStorage = (storageType, key) => {
  return Vault[storageType].remove(key);
};
