import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "orders",
    title: "Orders",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/orders",
  },
  {
    id: "customers",
    title: "Customers",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/customers",
  },
  {
    id: "products",
    title: "Products",
    type: "item",
    icon: <Icon.Tag size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/products",
  },
  {
    id: "payout-requests",
    title: "Payout Requests",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/payout-requests",
  },
];

export default navigationConfig;
